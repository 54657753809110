.bodyclassContact {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  font-family: "Open Sans";
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #308ecb 0%, #fafafa 40%);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100vw;
  padding-top: 20vh;
}
.contact-form h2 {
  font-size: 1.4vw;
}
h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.4vw;
}
.form-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 40.04vw;
  height: auto;
  justify-content: center;
}
label {
  font-weight: bold;
  margin-bottom: 5px;
}
input {
  width: 14.3vw;
  padding: 10px;
  margin-left: 3px;
  margin-right: 3px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
}
textarea {
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 29.8vw;
  height: 21.22vh;
  font-family: "Open Sans";

  /* height: .06vh; */
}

button {
  background-color: #272727;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  width: 150px;
  margin-bottom:10vh
}

button:hover {
  background-color: #000000;
}
@media ((min-width:0px) and (max-width:768px)) and ((min-height:375px) and (max-height:950px)){
    .container{
        padding-top: 15vh;
    }
    .container img{
        width: 40vw;
    }
    h2{
        font-size: 4vw;
    }
    .form-div{
        width: 100vw;
    }
    input{
        width: 40vw;
        height: 1.8vh;
    }
    textarea{
        height: 16.02vh;
        width: 85.2vw;

    }
    button{
        margin-bottom: 10vh;
    }
}