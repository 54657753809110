/* @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,700;1,800&family=Roboto+Condensed&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500;600&family=Open+Sans:wght@400;500&family=Roboto+Condensed&display=swap");

body {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  background-color: #f2f2f2;
  font-family: "IBM Plex Serif";
  margin: 0;
  padding: 0;
}

footer {
  background-color: #272727;
  padding: 20px;
  text-align: center;
  font-size: 1.01vw;
  width: 100vw;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 100%
}

footer a {
  margin: 0px 10px;
  text-decoration: none;
  color: #dddfe2;
}

.Press{
  display: none;
}
.Career{
  display: none;
}

.centralized {
  flex: 1 1;
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 140vh;
  /* justify-content: center; */
  flex-direction: column;
  background: linear-gradient(180deg, #308ecb 0%, #fafafa 80vh);

    height: 100%;
    width: 100%;
    overflow: hidden;

}

.content {
  text-align: center;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  padding: 20px;
  width: 39.06vw;
  margin-top: 10vh;
}

.content img {
  margin-bottom: 20px;
}

h1 {
  color: #000000;
  font-size: 2.5vw;
}

p {
  color: #000000;
  font-family: "Open Sans";
  font-size: 0.9vw;
  margin: 0.46vw;
}



.contact-form {
  /* background-image: linear-gradient(to top,rgba(236, 236, 19, 0.895), white); */
  background-color: transparent;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  padding: 40px;
  /* width: 400px; */

}

.container img {
  width: 15.675vw;
}


form {
  display: flex;
  flex-direction: column;

}






.item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 86vw;
  margin-bottom: 10vh;
  /* height: 83.1vh; */
}
.card-css {
  box-sizing: border-box;
  background-color: rgb(244, 244, 244);
  width: 19.53vw;
  height: auto;
  padding: 0.78vw;
  margin: 23px;
  border-radius: 3px;
  border: 0.2px solid rgb(255, 255, 255);
}
.card-css:hover {
  border: 0.2px solid rgb(0, 0, 0);
  background-color: #edf3f7;
  box-shadow: 3px 2.5px 1.5px rgba(39, 39, 39);
}
.card-css img {
  /* width: 40px; */
  width: 3.15vw;
  border-radius: 50%;
  margin-left: 0.46vw;
}
h3 {
  margin: 0.46vw;
  font-size: 1.2vw;
}
.random {
  color: white;
  font-size: 0.52vw;
}

h4 {
  font-size: 1.275vw;
  font-weight: 200;
  /* width: 100vw; */
}
.Ndax-logo {
  width: 15.675vw;
}

@media (min-width: 1300px) and (max-width: 1570px) {
  .centralized {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .card-css {
    height: auto;
    width: 20vw;
  }
}
@media (min-width: 1100px) and (max-width: 1299px) {
  .centralized {
    height: 100%;
  }
  .card-css {
    height: auto;
    width: 20vw;
  }
}
@media (min-width: 864px) and (max-width: 1099px) {
  .centralized {
    height: 100%;
  }
  .card-css {
    height: auto;
    width: 20vw;
  }
}
@media (min-width: 768px) and (max-width: 863px) {
  .centralized {
    height: 100%;
  }
  .card-css {
    height: auto;
    width: 20vw;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .centralized {
    height: 10vh;
  }
  .card-css {
    height: auto;
    width: 23vw;
  }
  .card-css img {
    width: 5vw;
  }
  h3 {
    font-size: 1.5vw;
  }
  p {
    font-size: 1vw;
  }
}
@media ((min-width: 0px) and (max-width:768px)) and ((min-height: 375px) and (max-height:950px)) {
  .centralized {
    height: 100%;
  }
  .card-css {
    height: auto;
    width: 36vw;
  }
  .item {
    width: 100vw;
  }
  .Ndax-logo {
    width: 40vw;
  }
  h1 {
    font-size: 4vw;
  }
  h4 {
    font-size: 1vh;
    font-weight: 100;
  }
  .card-css img {
    /* width: 40px; */
    width: 7vw;
  }
  h3 {
    font-size: 3vw;
  }
  p {
    font-size: 2vw;
  }
  footer a {
    font-size: 3vw;
  }
  .random {
    font-size: 1.5vw;
  }
  .container{
    padding-top:15vh;
  }
}


/* Career */

.Career{
height: 100%;
width: 100%;
}

.Career-div{
height: 80vh;
text-align: center;
}


/* Press */
.Press{
  height: 100%;
  width: 100%;
  }
  
  .press-div{
  height: 80vh;
  text-align: center;
  }